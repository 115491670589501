.loading {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    /* From https://css.glass */
    /* background: rgba(0, 0, 0, 0.2); */
    /* backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); */
    z-index: 10000;
    box-sizing: border-box;
}

.loading-text {
    font-size: 20px;
}